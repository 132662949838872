import { APP_ENV } from 'src/configs/config-global';

import { NodeEnvType } from '../types/types-env';

// ----------------------------------------------------------------------

export class ConfigBase<T> {
  public name: string;

  public environment: NodeEnvType;

  private _configs: Record<NodeEnvType, T>;

  constructor(
    name: string, // The name of the configuration
    local: T, // Default value for configs (local config)
    overrides?: Partial<Record<Exclude<NodeEnvType, NodeEnvType.Local>, T>>, // Overrides for other environments except local
    environment: NodeEnvType = APP_ENV // The environment to use
  ) {
    this.name = name;
    this.environment = environment;
    this._configs = {
      [NodeEnvType.Local]: local,
      [NodeEnvType.Development]: overrides?.[NodeEnvType.Development] ?? local,
      [NodeEnvType.Staging]: overrides?.[NodeEnvType.Staging] ?? local,
      [NodeEnvType.Production]: overrides?.[NodeEnvType.Production] ?? local,
    };

    this.validateConfigs(); // Validate configs at initialization
  }

  // Validate that required configurations are set for each environment
  private validateConfigs(): void {
    Object.keys(this._configs).forEach((env) => {
      const config = this._configs[env as NodeEnvType];
      if (!config) {
        throw new Error(`Constructing ${this.name}: Missing configuration for ${env}`);
      }
    });
  }

  // Retrieve the config based on the environment with fallback
  getSetup(env: NodeEnvType = this.environment): T {
    const config = this._configs[env];

    if (!config) {
      console.warn(`Config for ${env} not found. Falling back to 'local' config.`);
      return this._configs[NodeEnvType.Local];
    }
    return config;
  }
}
