import { createSlice } from '@reduxjs/toolkit';

import { SandboxConfig } from 'src/sections/canvas-viewer-section/types';

// ----------------------------------------------------------------------

type StateType = {
  totalAnnotations: number;
  showTotalAnnotations: boolean;
  showHeatmap: boolean;
  sandboxConfig: SandboxConfig;
  isInitialized: boolean;
  undoCount: number;
  redoCount: number;
  selectedAnnotationsCount: number;
};

const initialState: StateType = {
  totalAnnotations: 0,
  showTotalAnnotations: false,
  showHeatmap: false,
  sandboxConfig: { enabled: false },
  isInitialized: false,
  undoCount: 0,
  redoCount: 0,
  selectedAnnotationsCount: 0,
};

// ----------------------------------------------------------------------

export const sliceCanvas = createSlice({
  name: 'canvas',
  initialState,
  reducers: {
    updateTotalAnnotations: (state, { payload }) => {
      state.totalAnnotations = payload;
    },
    toggleTotalAnnotationsVisibility: (state, { payload }) => {
      state.showTotalAnnotations = payload ?? !state.showTotalAnnotations;
    },
    toggleHeatmapVisibility: (state, { payload }) => {
      state.showHeatmap = payload ?? !state.showHeatmap;
    },
    addSelectedAnnotationsCount: (state, { payload }) => {
      state.selectedAnnotationsCount = Math.max(state.selectedAnnotationsCount + payload, 0);
    },
    setIsInitialized: (state, { payload }) => {
      state.isInitialized = payload;
    },
    resetCanvas: (state) => {
      state.showTotalAnnotations = initialState.showTotalAnnotations;
      state.showHeatmap = initialState.showHeatmap;
      state.isInitialized = initialState.isInitialized;
    },
    setSandboxConfig: (state, { payload }) => {
      state.sandboxConfig = payload;
    },
    setUndoCount: (state, { payload }) => {
      state.undoCount = payload;
    },
    setRedoCount: (state, { payload }) => {
      state.redoCount = payload;
    },
  },
});

export const {
  updateTotalAnnotations,
  resetCanvas,
  toggleTotalAnnotationsVisibility,
  toggleHeatmapVisibility,
  setSandboxConfig,
  setIsInitialized,
  setRedoCount,
  setUndoCount,
  addSelectedAnnotationsCount,
} = sliceCanvas.actions;

export default sliceCanvas.reducer;
