import { PageType } from 'src/helpers/page-mapper/types';

// ----------------------------------------------------------------------

const ROOTS = {
  AUTH: '/auth',
  CANVAS: '/canvas',
  DASHBOARD: '/app',
};

// ----------------------------------------------------------------------

export const paths = {
  page403: '/403',
  page404: '/404',
  page500: '/500',
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  auth: {
    login: {
      id: PageType.LoginPage,
      to: () => `${ROOTS.AUTH}/login`,
    },
    register: {
      id: PageType.RegisterPage,
      to: () => `${ROOTS.AUTH}/register`,
    },
  },
  dashboard: {
    root: {
      id: '',
      to: () => `${ROOTS.DASHBOARD}`,
    },
    folder: {
      root: {
        id: PageType.FolderListPage,
        to: () => `${ROOTS.DASHBOARD}/folders`,
      },
      details: {
        id: PageType.FolderDetailsPage,
        to: (id: string) => `${ROOTS.DASHBOARD}/folders/${id}`,
      },
    },
    project: {
      root: {
        id: PageType.ProjectListPage,
        to: () => `${ROOTS.DASHBOARD}/ai-projects`,
      },
      details: {
        id: PageType.ProjectDetailsPage,
        to: (projectId: string, snapshotId?: string) => {
          if (snapshotId) {
            return `${ROOTS.DASHBOARD}/ai-projects/${projectId}/snapshots/${snapshotId}`;
          }
          return `${ROOTS.DASHBOARD}/ai-projects/${projectId}`;
        },
      },
    },
    aiModel: {
      root: {
        id: PageType.AiModelListPage,
        to: () => `${ROOTS.DASHBOARD}/ai-models`,
      },
      details: {
        id: PageType.AiModelDetailsPage,
        to: (id: string) => `${ROOTS.DASHBOARD}/ai-models/${id}`,
      },
      new: {
        id: PageType.AiModelNewPage,
        to: (projectId?: string, snapshotId?: string) => {
          if (projectId) {
            return snapshotId
              ? `${ROOTS.DASHBOARD}/ai-projects/${projectId}/snapshots/${snapshotId}/ai-models/new`
              : `${ROOTS.DASHBOARD}/ai-projects/${projectId}/ai-models/new`;
          }
          return `${ROOTS.DASHBOARD}/ai-models/new`;
        },
      },
    },
  },
  canvas: {
    imageCanvas: {
      id: PageType.ImageCanvasPage,
      to: (folderId: string, imageId: string) =>
        `${ROOTS.CANVAS}/folders/${folderId}/images/${imageId}`,
    },
    sketchCanvas: {
      id: PageType.SketchCanvasPage,
      to: (projectId: string, snapshotId: string, sketchId: string) =>
        `${ROOTS.CANVAS}/ai-projects/${projectId}/snapshots/${snapshotId}/images/${sketchId}`,
    },
  },
};
