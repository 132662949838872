export enum FeatureType {
  /* --------------------------------- Canvas --------------------------------- */
  CanvasThreads = 'feature-canvas-threads',
  CanvasHeatmap = 'feature-canvas-heatmap',
  CanvasRoiStats = 'feature-canvas-roi_stats',
  CanvasUndoRedo = 'feature-canvas-undo_redo',
  CanvasCopyPaste = 'feature-canvas-copy_paste',
  CanvasRabbitMenu = 'feature-canvas-rabbit_menu',
  CanvasContextMenu = 'feature-canvas-context_menu',
  CanvasOverviewMap = 'feature-canvas-overview_map',
  CanvasMovementStick = 'feature-canvas-movement_stick',
  CanvasLocateAnnotation = 'feature-canvas-locate_annotation',
  CanvasAdaptiveZoomMobile = 'feature-canvas-adaptive_zoom_mobile',
  CanvasAdaptiveZoomTablet = 'feature-canvas-adaptive_zoom_tablet',
  CanvasAdaptiveZoomDesktop = 'feature-canvas-adaptive_zoom_desktop',
  CanvasToolbarAutoAnnotate = 'feature-canvas-toolbar-auto_annotate',

  /* -------------------------------- Sections -------------------------------- */
  SectionProjectAiActions = 'feature-section-project_ai_actions',

  /* -------------------------------- Projects -------------------------------- */
  ProjectDuplicateAction = 'feature-project-duplicate_action',

  /* -------------------------------- App -------------------------------- */
  AppDarkMode = 'feature-app-dark_mode',
}
