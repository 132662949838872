import { useMemo } from 'react';
import useSWR, { SWRConfiguration } from 'swr';

import { request, RequestProps } from 'src/helpers/http';
import {
  DynamicPage,
  DynamicActionList,
  DynamicActionItem,
} from 'src/services/api/types/types-dynamic-components';

import { GET_INIT } from './endpoints';
import { ActionType } from './types/types-actions';

// ----------------------------------------------------------------------

export async function getInit() {
  const args: RequestProps = { url: GET_INIT, method: 'GET' };

  type Response = { actions: DynamicActionList<ActionType.Me> };

  const data = await request<Response>(args);

  return data;
}

// ----------------------------------------------------------------------

export function useGetPage(action: DynamicActionItem) {
  const args: RequestProps = { url: action.url, method: action.method };

  type Response = { page: DynamicPage };

  const options: SWRConfiguration = {
    // TODO: customize SWR config for useGetPage
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    suspense: true,
  };

  const { data, isLoading, error, isValidating } = useSWR(args, request<Response>, options);

  const memoizedValue = useMemo(
    () => ({
      page: data?.page,
      pageLoading: isLoading,
      pageError: error,
      pageValidating: isValidating,
    }),
    [data, error, isLoading, isValidating]
  );

  return memoizedValue;
}

// ----------------------------------------------------------------------
