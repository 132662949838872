import * as Sentry from '@sentry/react';
import axios, { AxiosError } from 'axios';
import { signOut, fetchAuthSession } from 'aws-amplify/auth';

import { paths } from 'src/routes/paths';
import History from 'src/routes/history';

import { API_URL } from 'src/configs/config-global';
import { LocalStorage } from 'src/helpers/local-storage';

import { enqueueSnackbar } from 'src/components/snackbar';

import { DEFAULT_ERROR_MSG } from './configs';
import { RequestProps, ResponseType } from './types';
import { mapStatus, getAlertDirection } from './helpers';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

axiosInstance.interceptors.request.use(
  async (config) => {
    // Protect Request: add `Authorization` header if token exists, or `X-API-KEY` if not provided.
    const { idToken } = (await fetchAuthSession()).tokens ?? {};
    if (idToken) {
      config.headers.Authorization = `Bearer ${idToken.toString()}`;
    } else {
      // config.headers['X-Api-Key'] = API_KEY;
    }

    // Add language and locale header
    const language = LocalStorage.getItem('i18nextLng');
    if (language) {
      config.headers['Accept-Language'] = language;
    }

    // Uploading Files using FormData
    if (config.data instanceof FormData) {
      config.headers['Content-Type'] = 'multipart/form-data';
    }

    return config;
  },
  (error: AxiosError) => {}
);

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error: AxiosError) => {
    const { response } = error;

    Sentry.captureException(error);

    const notification = {
      message:
        (error?.response?.data as ResponseType)?.message ?? error?.message ?? DEFAULT_ERROR_MSG,
      duration: 3000,
    };
    if (notification.message.toString() === '[object Object]')
      notification.message = DEFAULT_ERROR_MSG;

    // If the request is canceled, we avoid showing an error notification
    if (axios.isCancel(error)) console.info('Request canceled', error.message);

    let returnTo: string;
    switch (response?.status) {
      case 401: // Handle Unauthorized
        await signOut();
        enqueueSnackbar(notification.message, {
          anchorOrigin: { vertical: 'top', horizontal: getAlertDirection() },
          variant: mapStatus('error'),
          autoHideDuration: notification.duration,
        });
        await new Promise((resolve) => setTimeout(resolve, notification.duration)); // Delay execution until notification is hidden

        returnTo = paths.auth.login.to();
        if (window.location.href.match(returnTo) === null) {
          window.location.href = returnTo; // Redirect
        }
        break;

      case 403: // Handle Forbidden Not Allowed
        returnTo = paths.page403;
        History.push(returnTo, { replace: true }); // Redirect
        break;

      case 404: // Handle Not Found
        returnTo = paths.page404;
        History.push(returnTo, { replace: true }); // Redirect
        break;

      default: // Handle other errors
        break;
    }

    enqueueSnackbar(notification.message, {
      anchorOrigin: { vertical: 'top', horizontal: getAlertDirection() },
      variant: mapStatus('error'),
      autoHideDuration: notification.message === DEFAULT_ERROR_MSG ? null : notification.duration,
    });

    return Promise.reject(error);
  }
);

// ----------------------------------------------------------------------

const request = async <T = any>({
  url,
  method,
  enableShowToast = false, // By default (method !== 'GET'), don't show toasts ever to avoid alert fatigue for now
  ...args
}: RequestProps) => {
  try {
    const response = await axiosInstance({ url, method, ...args });
    const { status, data, message } = response.data as ResponseType<T>;

    if (enableShowToast) {
      enqueueSnackbar(message, {
        anchorOrigin: { vertical: 'top', horizontal: getAlertDirection() },
        variant: mapStatus(status),
        autoHideDuration: 3000,
      });
    }

    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export default request;
