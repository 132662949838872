import { VariantType } from 'src/components/snackbar';

import { ResponseType } from './types';
import { LocalStorage } from '../local-storage';

// ----------------------------------------------------------------------

export const mapStatus = (status: ResponseType['status'] | null): VariantType => {
  switch (status) {
    case 'success':
      return 'success';
    case 'fail':
    case 'error':
      return 'error';
    default:
      return 'error';
  }
};

export const getAlertDirection = () => {
  const settings = LocalStorage.getItem('settings');

  return settings?.themeDirection === 'ltr' ? 'right' : 'left';
};
