// ----------------------------------------------------------------------

export enum AmplitudeEventsType {
  CreateAiModel = 'create-new-ai-model',
  AuthRegister = 'auth-register-request',
  CanvasToolChosen = 'canvas-tool-chosen',
  AppErrorEvent = 'app-error',
}

export type AuthRegisterEvent = {
  'first-name': string;
  'last-name': string;
  'org-name': string;
  'email-address': string;
  'use-case-purpose': string;
};

export type CreateAiModelEvent = {
  'project-id': string;
  'snapshot-id': string;
};

export type CanvasToolChosenEvent = {
  'tool-name': string;
  'chosen-via': UserInteractionViaType;
};

export enum UserInteractionViaType {
  OnClick = 'on-click',
}
