import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import { LocalStorage } from 'src/helpers/local-storage';
import { StorageKeysType } from 'src/configs/types/types-storage';

import { defaultLang } from './config-lang';
import translationEn from './langs/en.json';

// ----------------------------------------------------------------------

const lng = LocalStorage.getItem(StorageKeysType.I18n) ?? defaultLang.value;

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: { translations: translationEn },
    },
    lng,
    fallbackLng: 'en',
    debug: false,
    ns: ['translations'],
    defaultNS: 'translations',
    interpolation: {
      escapeValue: false,
      skipOnVariables: false,
    },
  });

export default i18n;
