// ----------------------------------------------------------------------

export type PageMapperType = {
  [key in PageType]: Function;
};

// ----------------------------------------------------------------------

export enum PageType {
  // Auth
  LoginPage = 'login_page',
  RegisterPage = 'register_page',

  // Dashboard
  FolderListPage = 'folders_page',
  FolderDetailsPage = 'folder_page',
  ProjectListPage = 'ai_projects_page',
  ProjectDetailsPage = 'ai_project_page',
  AiModelListPage = 'ai_models_page',
  AiModelDetailsPage = 'ai_model_page',
  AiModelNewPage = 'ai_model_creation_page',

  // Canvas
  SketchCanvasPage = 'snapshot_image_canvas_page',
  ImageCanvasPage = 'folder_image_canvas_page',
}
