import { SWRConfig } from 'swr';
import { Suspense } from 'react';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

import { store } from 'src/store';
import { initAppServices } from 'src/helpers/init-app-services';

import router from './routes/sections/index';
import { MittProvider } from './services/canvas/hooks/use-mitt';

// ----------------------------------------------------------------------

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

initAppServices();

root.render(
  <HelmetProvider>
    <Suspense>
      <Provider store={store}>
        <MittProvider>
          <SWRConfig>
            <RouterProvider router={router} />
          </SWRConfig>
        </MittProvider>
      </Provider>
    </Suspense>
  </HelmetProvider>
);
