import { createSlice } from '@reduxjs/toolkit';

import { detectDeviceType } from 'src/utils/user-agent';

import { configCanvasPage } from 'src/configs/config-canvas';
import { PostCanvasSaveRes } from 'src/services/api/types/types-req-res';

import { HandleSaveChangesProps } from 'src/sections/section-annotations-manager/types';

// ----------------------------------------------------------------------

type StateType = {
  pageSaving: boolean;
  sidePanelCollapsed: boolean;
  showImagesCarousel: boolean;
  saveChangesFunction: (payload: HandleSaveChangesProps) => Promise<PostCanvasSaveRes>;
};

const initialState: StateType = {
  pageSaving: false,
  showImagesCarousel: true,
  sidePanelCollapsed:
    detectDeviceType() === 'Desktop'
      ? configCanvasPage.sections.rightpanel.initialState.collapsed.desktop
      : configCanvasPage.sections.rightpanel.initialState.collapsed.tablet,
  saveChangesFunction: async () => ({
    create: { ai_project_snapshot_classes: [], annotations: [] },
    update: { ai_project_snapshot_classes: [], annotations: [] },
  }),
};

// ----------------------------------------------------------------------

export const slicePageCanvas = createSlice({
  name: 'pageCanvas',
  initialState,
  reducers: {
    collapseSidePanel: (state) => {
      state.sidePanelCollapsed = true;
    },
    extendSidePanel: (state) => {
      state.sidePanelCollapsed = false;
    },
    toggleSidePanel: (state) => {
      state.sidePanelCollapsed = !state.sidePanelCollapsed;
    },
    showImagesCarousel: (state) => {
      state.showImagesCarousel = true;
    },
    hideImagesCarousel: (state) => {
      state.showImagesCarousel = false;
    },
    toggleImagesCarousel: (state) => {
      state.showImagesCarousel = !state.showImagesCarousel;
    },
    setCanvasSaving: (state, { payload }) => {
      state.pageSaving = payload;
    },
    setSaveChangesFunction: (state, { payload }) => {
      state.saveChangesFunction = payload;
    },
    resetPageCanvas: (state) => {
      state.pageSaving = initialState.pageSaving;
      state.saveChangesFunction = initialState.saveChangesFunction;
      state.showImagesCarousel = initialState.showImagesCarousel;
      state.sidePanelCollapsed = initialState.sidePanelCollapsed;
    },
  },
});

export const {
  setCanvasSaving,
  extendSidePanel,
  toggleSidePanel,
  resetPageCanvas,
  collapseSidePanel,
  showImagesCarousel,
  hideImagesCarousel,
  toggleImagesCarousel,
  setSaveChangesFunction,
} = slicePageCanvas.actions;

export default slicePageCanvas.reducer;
