import { lazy, Suspense } from 'react';
import { Outlet, Navigate } from 'react-router-dom';

import i18n from 'src/locales/i18n';
import { GuestGuard } from 'src/services/auth/guard';
import { WEBSITE_NAME } from 'src/configs/config-global';
import AuthClassicLayout from 'src/layouts/auth/classic';

import { SplashScreen } from 'src/components/loading-screen';

import { paths } from '../paths';

// ----------------------------------------------------------------------

const LoginPage = lazy(() => import('src/pages/auth/login'));
const RegisterPage = lazy(() => import('src/pages/auth/register'));

// ----------------------------------------------------------------------

const auth = {
  path: '',
  element: (
    <Suspense fallback={<SplashScreen />}>
      <Outlet />
    </Suspense>
  ),
  children: [
    { index: true, element: <Navigate to={paths.auth.login.to()} replace /> },
    {
      path: 'register',
      element: (
        <GuestGuard>
          <AuthClassicLayout
            title={i18n.t('auth.get_started_with', { website: WEBSITE_NAME })}
            image='/assets/illustrations/illustration_authentication.svg'
          >
            <RegisterPage />
          </AuthClassicLayout>
        </GuestGuard>
      ),
    },
    {
      path: 'login',
      element: (
        <GuestGuard>
          <AuthClassicLayout
            title={i18n.t('auth.welcome_to', { website: WEBSITE_NAME })}
            image='/assets/illustrations/illustration_authentication.svg'
          >
            <LoginPage />
          </AuthClassicLayout>
        </GuestGuard>
      ),
    },
  ],
};

export const authRoutes = [{ path: 'auth', children: [auth] }];
