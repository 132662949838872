import { lazy, Suspense } from 'react';
import { Outlet, Navigate } from 'react-router-dom';

import CanvasLayout from 'src/layouts/canvas';
import { AuthGuard } from 'src/services/auth/guard';
import { PATH_AFTER_LOGIN } from 'src/configs/config-global';
import { Params } from 'src/helpers/route-placeholder-mapper/types';

import { SplashScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------

const PageImageCanvas = lazy(() => import('src/pages/canvas/page-image-canvas/image-canvas'));
const PageSketchCanvas = lazy(() => import('src/pages/canvas/page-sketch-canvas/sketch-canvas'));

// ----------------------------------------------------------------------

export const canvasRoutes = [
  {
    path: 'canvas',
    element: (
      <AuthGuard>
        <CanvasLayout>
          <Suspense fallback={<SplashScreen />}>
            <Outlet />
          </Suspense>
        </CanvasLayout>
      </AuthGuard>
    ),
    children: [
      { index: true, element: <Navigate to={PATH_AFTER_LOGIN} replace /> },
      {
        path: `ai-projects/:${Params.ProjectId}/snapshots/:${Params.SnapshotId}/images/:${Params.SketchId}`,
        element: <PageSketchCanvas />,
      },
      {
        path: `folders/:${Params.FolderId}/images/:${Params.ImageId}`,
        element: <PageImageCanvas />,
      },
    ],
  },
];
