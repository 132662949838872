import Icon from 'ol/style/Icon';
import Fill from 'ol/style/Fill';
import Style from 'ol/style/Style';
import Stroke from 'ol/style/Stroke';
import CircleStyle from 'ol/style/Circle';

import { ConfigBase } from './helpers/config-base';

// ----------------------------------------------------------------------

export const configCanvasPage = new ConfigBase('pages:sketch_canvas', {
  sections: {
    rightpanel: {
      initialState: {
        collapsed: { desktop: false, tablet: true },
      },
    },
  },
  reloadOnNavigate: true,
}).getSetup();

// ----------------------------------------------------------------------

export const configCanvasHeatmap = new ConfigBase('canvas:heatmap', {
  style: {
    blur: 55,
    radius: 10,
  },
}).getSetup();

// ----------------------------------------------------------------------

export const configCanvasThreads = new ConfigBase('canvas:heatmap', {
  style: {
    feature: new Style({
      image: new Icon({
        anchor: [0, 0],
        anchorXUnits: 'fraction',
        anchorYUnits: 'pixels',
        src: '/assets/icons/canvas/uim--comment-message.svg',
      }),
    }),
    cursor: 'url(/assets/icons/canvas/uil--comment-medical.svg), pointer',
    layer: { strokeWidth: 2, strokeColor: 'transparent', fillColor: 'transparent' },
  },
}).getSetup();

// ----------------------------------------------------------------------

export const configCanvasBrush = new ConfigBase('canvas:brush', {
  size: 1000,
  range: [2, 3000],
}).getSetup();

// ----------------------------------------------------------------------

export const configCanvasViewer = new ConfigBase('canvas:viewer', {
  paddingRatio: 0.5,
  style: {
    zIndex: 1,
    layer: { strokeWidth: 2, strokeColor: '#FFFF00', fillColor: 'rgba(255,255,255,0)' },
    layerHidden: { strokeWidth: 2, strokeColor: 'transparent', fillColor: 'transparent' },
  },
}).getSetup();

// ----------------------------------------------------------------------

export const configCanvasMovementStick = new ConfigBase('canvas:movement_stick', {
  distance: 200,
  transitionDuration: 250,
}).getSetup();

// ----------------------------------------------------------------------

export const configCanvasAdaptiveZoom = new ConfigBase('canvas:adaptive_zoom', {
  minAnnotations: [5000, 500, 500],
  minAnnotationAreaToRender: 0.00005,
  minResolutionToLocateAnnotation: 1,
}).getSetup();

// ----------------------------------------------------------------------

export const configCanvasTools = new ConfigBase('canvas:tools', {
  draw: {
    polygon: {
      style: new Style({
        stroke: new Stroke({ color: '#FFF', width: 2 }),
      }),
    },
    innerPolygon: {
      style: new Style({
        fill: new Fill({ color: 'rgba(255, 255, 255, 0.2)' }),
        stroke: new Stroke({ color: '#0099FF', width: 1, lineJoin: 'round' }),
        image: new CircleStyle({
          radius: 7,
          fill: new Fill({ color: '#0099FF' }),
          stroke: new Stroke({ color: '#FFF', width: 2 }),
        }),
      }),
    },
    brush: {
      style: new Style({
        stroke: new Stroke({ color: '#FFF', width: 2, lineJoin: 'round' }),
        fill: new Fill({ color: 'rgba(0, 153, 255, 0.5)' }),
        image: new CircleStyle({
          radius: 7,
          fill: new Fill({ color: '#0099FF' }),
          stroke: new Stroke({ color: '#FFF', width: 2 }),
        }),
      }),
    },
  },
  select: {
    polygon: {
      style: new Style({
        fill: new Fill({ color: 'rgba(255, 255, 255, 0.2)' }),
        stroke: new Stroke({ color: 'orange', width: 2, lineDash: [10, 10] }),
        image: new CircleStyle({
          radius: 7,
          fill: new Fill({ color: 'orange' }),
          stroke: new Stroke({ color: '#FFF', width: 2 }),
        }),
      }),
    },
  },
}).getSetup();

// ----------------------------------------------------------------------
