import { lazy, Suspense } from 'react';
import { Outlet, Navigate } from 'react-router-dom';

import { AuthGuard } from 'src/services/auth/guard';
import DashboardLayout from 'src/layouts/dashboard';
import { PATH_AFTER_LOGIN } from 'src/configs/config-global';
import { Params } from 'src/helpers/route-placeholder-mapper/types';

import { LoadingScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------

// OVERVIEW
// const IndexPage = lazy(() => import('src/pages/dashboard/app'));
// FOLDER
const PageFolderList = lazy(() => import('src/pages/dashboard/folder/page-folder-list/list'));
const PageFolderDetails = lazy(
  () => import('src/pages/dashboard/folder/page-folder-details/details')
);
// PROJECT
const ProjectListPage = lazy(() => import('src/pages/dashboard/project/list'));
const ProjectDetailsPage = lazy(() => import('src/pages/dashboard/project/details'));
// AI MODELS
const AiModelNewPage = lazy(() => import('src/pages/dashboard/ai-model/new'));
const AiModelListPage = lazy(() => import('src/pages/dashboard/ai-model/list'));
const AiModelDetailsPage = lazy(() => import('src/pages/dashboard/ai-model/details'));

// ----------------------------------------------------------------------

export const dashboardRoutes = [
  {
    path: 'app',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      {
        index: true,
        // element: <IndexPage />,
        element: <Navigate to={PATH_AFTER_LOGIN} replace />,
      },
      {
        path: 'folders',
        children: [
          { index: true, element: <PageFolderList /> },
          { path: `:${Params.FolderId}`, element: <PageFolderDetails /> },
        ],
      },
      {
        path: 'ai-projects',
        children: [
          { index: true, element: <ProjectListPage /> },
          {
            path: `:${Params.ProjectId}/snapshots/:${Params.SnapshotId}`,
            children: [
              { index: true, element: <ProjectDetailsPage /> },
              { path: `ai-models/new`, element: <AiModelNewPage /> },
            ],
          },
          {
            path: `:${Params.ProjectId}`,
            children: [
              { index: true, element: <ProjectDetailsPage /> },
              { path: `ai-models/new`, element: <AiModelNewPage /> },
            ],
          },
        ],
      },
      {
        path: 'ai-models',
        children: [
          { index: true, element: <AiModelListPage /> },
          { path: 'new', element: <AiModelNewPage /> },
          { path: `:${Params.AiModelId}`, element: <AiModelDetailsPage /> },
        ],
      },
    ],
  },
];
