import { useSWRConfig } from 'swr';
import { Amplify } from 'aws-amplify';
import { useMemo, useEffect, useReducer, useCallback } from 'react';
import {
  signIn,
  signOut,
  getCurrentUser,
  fetchAuthSession,
  fetchUserAttributes,
} from 'aws-amplify/auth';

import { request } from 'src/helpers/http';
import { useAppSelector } from 'src/store/hooks';
import { LocalStorage } from 'src/helpers/local-storage';
import AmplitudeService from 'src/services/amplitude/amplitude-service';
import { setSentryUser, clearSentryUser } from 'src/services/sentry/sentry-service';
import {
  AWS_AMPLIFY_USER_POOL_ID,
  AWS_AMPLIFY_USER_POOL_WEB_CLIENT_ID,
} from 'src/configs/config-services';

import { UserAttributes } from 'src/types/types-user';

import { AuthContext } from './auth-context';
import { Me, ActionMapType, AuthStateType, AuthMethodType } from '../types';

// ----------------------------------------------------------------------

/**
 * DOCS: https://docs.amplify.aws/react/tools/libraries/configure-categories/#authentication-amazon-cognito
 */
Amplify.configure({
  Auth: {
    Cognito: {
      userPoolId: AWS_AMPLIFY_USER_POOL_ID,
      userPoolClientId: AWS_AMPLIFY_USER_POOL_WEB_CLIENT_ID,
    },
  },
});

// ----------------------------------------------------------------------

enum Types {
  INITIAL = 'INITIAL',
  LOGIN = 'LOGIN',
  LOGOUT = 'LOGOUT',
}

type Payload = {
  [Types.INITIAL]: {
    user: AuthStateType['user'];
    allowedPages: AuthStateType['allowedPages'];
    navigationPages: AuthStateType['navigationPages'];
  };
  [Types.LOGIN]: {
    user: AuthStateType['user'];
    allowedPages: AuthStateType['allowedPages'];
    navigationPages: AuthStateType['navigationPages'];
  };
  [Types.LOGOUT]: undefined;
};

type Action = ActionMapType<Payload>[keyof ActionMapType<Payload>];

// ----------------------------------------------------------------------

const initialState: AuthStateType = {
  user: null,
  loading: true,
  allowedPages: [],
  navigationPages: [],
};

const reducer = (state: AuthStateType, action: Action) => {
  if (action.type === Types.INITIAL) {
    return {
      loading: false,
      user: action.payload.user,
      allowedPages: action.payload.allowedPages,
      navigationPages: action.payload.navigationPages,
    };
  }
  if (action.type === Types.LOGIN) {
    return {
      ...state,
      user: action.payload.user,
      allowedPages: action.payload.allowedPages,
      navigationPages: action.payload.navigationPages,
    };
  }
  if (action.type === Types.LOGOUT) {
    return {
      ...state,
      user: null,
      allowedPages: [],
      navigationPages: [],
    };
  }
  return state;
};

// ----------------------------------------------------------------------

type Props = { children: React.ReactNode };

export function AuthProvider({ children }: Props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { meAction } = useAppSelector(({ app }) => app);
  const { mutate } = useSWRConfig();

  const initialize = useCallback(async () => {
    try {
      const { userId: currentUser } = await getCurrentUser();

      const userAttributes = (await fetchUserAttributes()) as UserAttributes;

      const { idToken, accessToken } = (await fetchAuthSession()).tokens ?? {};

      if (currentUser && meAction) {
        const { allowed_pages, user, navigation_pages } = await request<Me>(meAction);

        setSentryUser(user);
        LocalStorage.setItem('user', user);

        dispatch({
          type: Types.INITIAL,
          payload: {
            user: {
              ...user,
              ...userAttributes,
              idToken,
              accessToken,
              id: userAttributes.sub,
              displayName: `${user.first_name} ${user.last_name}`,
            },
            allowedPages: allowed_pages,
            navigationPages: navigation_pages,
          },
        });
      } else {
        dispatch({
          type: Types.INITIAL,
          payload: {
            user: null,
            allowedPages: [],
            navigationPages: [],
          },
        });
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: Types.INITIAL,
        payload: {
          user: null,
          allowedPages: [],
          navigationPages: [],
        },
      });
    }
  }, [meAction]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const login = useCallback(
    async (email: string, password: string) => {
      try {
        await signIn({ username: email, password });
      } catch (error) {
        if (error?.message === 'There is already a signed in user.') {
          console.error(error);
        } else {
          throw error;
        }
      }

      const userAttributes = (await fetchUserAttributes()) as UserAttributes;

      const { idToken, accessToken } = (await fetchAuthSession()).tokens ?? {};

      if (meAction) {
        const { allowed_pages, user, navigation_pages } = await request<Me>(meAction);

        setSentryUser(user);
        LocalStorage.setItem('user', user);

        dispatch({
          type: Types.LOGIN,
          payload: {
            user: {
              ...user,
              ...userAttributes,
              idToken,
              accessToken,
              id: userAttributes.sub,
              displayName: `${user.first_name} ${user.last_name}`,
            },
            allowedPages: allowed_pages,
            navigationPages: navigation_pages,
          },
        });
      } else {
        dispatch({
          type: Types.LOGIN,
          payload: { user: null, allowedPages: [], navigationPages: [] },
        });
      }
    },
    [meAction]
  );

  // REGISTER
  const register = useCallback(
    async (
      firstname: string,
      lastname: string,
      organization: string,
      email: string,
      useCasePurpose: string
    ) => {
      await AmplitudeService.logAuthRegisterEvent(
        firstname,
        lastname,
        organization,
        email,
        useCasePurpose
      );
    },
    []
  );

  // LOGOUT
  const logout = useCallback(async () => {
    await signOut();
    clearSentryUser();
    LocalStorage.removeItem('user');
    dispatch({ type: Types.LOGOUT });
    mutate(() => true, undefined, { revalidate: false }); // clear all cache using swr
  }, [mutate]);

  // ----------------------------------------------------------------------

  const checkAuthenticated = state.user ? 'authenticated' : 'unauthenticated';

  const status = state.loading ? 'loading' : checkAuthenticated;

  const memoizedValue = useMemo(
    () => ({
      user: state.user,
      allowedPages: state.allowedPages,
      navigationPages: state.navigationPages,
      method: AuthMethodType.Amplify,
      loading: status === 'loading',
      authenticated: status === 'authenticated',
      unauthenticated: status === 'unauthenticated',
      //
      login,
      register,
      logout,
    }),
    [
      status,
      state.user,
      state.allowedPages,
      state.navigationPages,
      //
      login,
      register,
      logout,
    ]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}
