enum OSType {
  Windows10 = 'Windows 10',
  Windows81 = 'Windows 8.1',
  Windows8 = 'Windows 8',
  Windows7 = 'Windows 7',
  Mac = 'Mac',
  Ios = 'iOS',
  Android = 'Android',
  Linux = 'Linux',
  Unknown = 'Unknown OS',
}

interface UserAgentInfo {
  browser: string;
  os: OSType;
}

export enum DeviceType {
  Mobile = 'Mobile',
  Tablet = 'Tablet',
  Desktop = 'Desktop',
}

// ----------------------------------------------------------------------

const browserRegexes = [
  { name: 'Edge', regex: /Edg\/(\d+)/ },
  { name: 'Chrome', regex: /Chrome\/(\d+)/ },
  { name: 'Firefox', regex: /Firefox\/(\d+)/ },
  { name: 'Safari', regex: /Version\/(\d+).*Safari/ },
  { name: 'Opera', regex: /OPR\/(\d+)/ },
  { name: 'IE', regex: /MSIE (\d+)/ },
  { name: 'IE11', regex: /Trident.*rv:(\d+)/ },
];

const osRegexes = [
  { name: OSType.Windows10, regex: /Windows NT 10.0/ },
  { name: OSType.Windows81, regex: /Windows NT 6.3/ },
  { name: OSType.Windows8, regex: /Windows NT 6.2/ },
  { name: OSType.Windows7, regex: /Windows NT 6.1/ },
  { name: OSType.Mac, regex: /Macintosh/ },
  { name: OSType.Ios, regex: /iPhone|iPad|iPod/ },
  { name: OSType.Android, regex: /Android/ },
  { name: OSType.Linux, regex: /Linux/ },
];

// ----------------------------------------------------------------------

export function parseUserAgent(userAgent: string = navigator.userAgent): UserAgentInfo {
  // Parse browser
  let browser = 'Unknown Browser';
  // eslint-disable-next-line no-restricted-syntax
  for (const { name, regex } of browserRegexes) {
    const match = userAgent.match(regex);
    if (match) {
      browser = `${name} ${match[1]}`; // Append browser version (match[1])
      break;
    }
  }

  // Parse OS
  let os = OSType.Unknown;
  // eslint-disable-next-line no-restricted-syntax
  for (const { name, regex } of osRegexes) {
    if (regex.test(userAgent)) {
      os = name;
      break;
    }
  }

  return { browser, os };
}

export function isMacOS(userAgent: string = navigator.userAgent): boolean {
  const userAgentInfo = parseUserAgent(userAgent);
  return userAgentInfo.os === OSType.Mac;
}

export function isWinOs(userAgent: string = navigator.userAgent): boolean {
  const userAgentInfo = parseUserAgent(userAgent);
  return (
    userAgentInfo.os === OSType.Windows10 ||
    userAgentInfo.os === OSType.Windows81 ||
    userAgentInfo.os === OSType.Windows8 ||
    userAgentInfo.os === OSType.Windows7
  );
}

export function detectDeviceType() {
  try {
    const { userAgent } = navigator ?? {};

    if (/Mobi|Android/i.test(userAgent)) {
      if (/Tablet|iPad/i.test(userAgent)) return 'Tablet';

      return DeviceType.Mobile;
    }

    const isIpad = /iPad|Macintosh/.test(navigator.userAgent) && 'ontouchend' in document;

    return isIpad ? DeviceType.Tablet : DeviceType.Desktop;
  } catch (e) {
    console.error(e);
    return DeviceType.Desktop;
  }
}
