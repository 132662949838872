import { LocalStorage } from 'src/helpers/local-storage';

import { ReqAndPart } from 'src/types/types-util';
import { UserRoleType } from 'src/types/types-user';

import { ConfigBase } from './config-base';
import { NodeEnvType } from '../types/types-env';
import { FeatureType } from '../types/types-features';
import { APP_ENV, APP_VERSION } from '../config-global';
import { StorageKeysType } from '../types/types-storage';

// ----------------------------------------------------------------------

interface FeatureOptions {
  enabled: boolean;
  accessRoles?: UserRoleType[];
  dependencies?: FeatureType[];
  minVersion?: string;
}

export class ConfigFeature extends ConfigBase<FeatureOptions> {
  constructor(
    name: FeatureType,
    local: ReqAndPart<FeatureOptions, 'enabled'>,
    overrides?: Partial<Record<Exclude<NodeEnvType, NodeEnvType.Local>, FeatureOptions>>,
    environment: NodeEnvType = APP_ENV
  ) {
    super(name, local, overrides, environment);
  }

  get isEnabled() {
    return this.isFeatureEnabled() && this.canAccessFeature() && this.isFeatureVersionCompatible();
  }

  // Method to get whether the feature is enabled for the current environment
  private isFeatureEnabled(): boolean {
    return this.getSetup().enabled;
  }

  // Check role-based access for the feature
  private canAccessFeature(): boolean {
    const user = LocalStorage.getItem(StorageKeysType.User);
    const config = this.getSetup();

    if (!user) return false;
    if (!config.accessRoles) return true;

    return config.accessRoles.includes(user.role);
  }

  // Ensure version compatibility
  private isFeatureVersionCompatible(): boolean {
    const config = this.getSetup();

    if (!config.minVersion) return true;

    return this.isVersionGreaterOrEqual(APP_VERSION, config.minVersion);
  }

  // Private helper to compare versions
  // eslint-disable-next-line class-methods-use-this
  private isVersionGreaterOrEqual(version: string, minVersion: string): boolean {
    // Version comparison logic
    return true; // Dummy logic for simplicity
  }
}
