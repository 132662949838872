import { Feature } from 'ol';
import { Geometry } from 'ol/geom';
import {
  Feature as TurfFeature,
  Polygon as TurfPolygon,
  MultiPolygon as TurfMultiPolygon,
} from '@turf/helpers';

import { IAnnotation } from '../../../types/types-tissue';

// ----------------------------------------------------------------------

export interface AnnotationStyle {
  strokeColor: string;
  strokeWidth: number;
  fillColor: string;
}

export interface Annotation extends Partial<IAnnotation> {
  uuid: string | number;
  drew: boolean;
  locked: boolean;
  syncing: boolean;
  visible: boolean;
  selected: boolean;
  feature: Feature<Geometry>;
  geoJSON?: TurfFeature<TurfPolygon | TurfMultiPolygon>;
}

export enum AnnotationAction {
  Add = 'add',
  Update = 'update',
  Remove = 'remove',
  State = 'state',
}

export enum DrawType {
  Point = 'Point',
  LineString = 'LineString',
  Polygon = 'Polygon',
  Circle = 'Circle',
  Rectangle = 'Rectangle',
}

export enum Brush {
  Brush = 'Brush',
  BrushDraw = 'Brush_Draw',
}

export enum SelectType {
  Edit = 'edit',
  Box = 'Select_Box',
  Translate = 'translate',
  Single = 'Select_Single',
  Polygon = 'Select_Polygon',
}

export enum ThreadCommentType {
  Comment = 'comment',
}

export type AnnotationMode = DrawType | SelectType | Brush | ThreadCommentType | null;

export type HistoryAction = 'create' | 'update' | 'delete' | 'update_class';

export type HistoryItem = {
  action: HistoryAction;
  annotation: Annotation;
  before?: Geometry;
  after?: Geometry;
};
