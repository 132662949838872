import {
  Brush,
  DrawType,
  SelectType,
  ThreadCommentType,
} from 'src/services/canvas/types/types-canvas';

import { UserRoleType } from 'src/types/types-user';

import { FeatureType } from './types/types-features';
import { ConfigFeature } from './helpers/config-feature';

/* --------------------------------- Canvas; -------------------------------- */

export const featCanvasRabbitMenu = new ConfigFeature(FeatureType.CanvasRabbitMenu, {
  enabled: true,
});

export const featCanvasContextMenu = new ConfigFeature(FeatureType.CanvasContextMenu, {
  enabled: true,
});

export const featCanvasUndoRedo = new ConfigFeature(FeatureType.CanvasUndoRedo, {
  enabled: true,
});

export const featCanvasThreads = new ConfigFeature(FeatureType.CanvasThreads, {
  enabled: true,
});

export const featCanvasHeatmap = new ConfigFeature(FeatureType.CanvasHeatmap, {
  enabled: true,
});

export const featCanvasRoiStats = new ConfigFeature(FeatureType.CanvasRoiStats, {
  enabled: true,
});

export const featCanvasCopyPaste = new ConfigFeature(FeatureType.CanvasCopyPaste, {
  enabled: true,
});

export const featCanvasLocateAnnotation = new ConfigFeature(FeatureType.CanvasLocateAnnotation, {
  enabled: false,
});

export const featCanvasAdaptiveZoom = {
  mobile: new ConfigFeature(FeatureType.CanvasAdaptiveZoomMobile, {
    enabled: false,
  }),
  tablet: new ConfigFeature(FeatureType.CanvasAdaptiveZoomTablet, {
    enabled: false,
  }),
  desktop: new ConfigFeature(FeatureType.CanvasAdaptiveZoomDesktop, {
    enabled: false,
  }),
};

export const featCanvasMovementStick = new ConfigFeature(FeatureType.CanvasMovementStick, {
  enabled: true,
});

export const featCanvasOverviewMap = new ConfigFeature(FeatureType.CanvasOverviewMap, {
  enabled: true,
});

// TODO: update the string enum for each feature
export const featCanvasTools = {
  autoAnnotate: new ConfigFeature(FeatureType.CanvasToolbarAutoAnnotate, {
    enabled: true,
    accessRoles: [UserRoleType.Admin],
  }),
  draw: new ConfigFeature(FeatureType.CanvasAdaptiveZoomMobile, {
    enabled: true,
  }),
  brush: new ConfigFeature(FeatureType.CanvasAdaptiveZoomMobile, {
    enabled: true,
  }),
  do: new ConfigFeature(FeatureType.CanvasAdaptiveZoomMobile, {
    enabled: featCanvasUndoRedo.isEnabled,
  }),
  undo: new ConfigFeature(FeatureType.CanvasAdaptiveZoomMobile, {
    enabled: featCanvasUndoRedo.isEnabled,
  }),
  divider: new ConfigFeature(FeatureType.CanvasAdaptiveZoomMobile, {
    enabled: true,
  }),
  [SelectType.Edit]: new ConfigFeature(FeatureType.CanvasAdaptiveZoomMobile, {
    enabled: true,
  }),
  [SelectType.Translate]: new ConfigFeature(FeatureType.CanvasAdaptiveZoomMobile, {
    enabled: true,
  }),
  [DrawType.Point]: new ConfigFeature(FeatureType.CanvasAdaptiveZoomMobile, {
    enabled: true,
  }),
  [DrawType.Circle]: new ConfigFeature(FeatureType.CanvasAdaptiveZoomMobile, {
    enabled: true,
  }),
  [DrawType.Rectangle]: new ConfigFeature(FeatureType.CanvasAdaptiveZoomMobile, {
    enabled: true,
  }),
  [Brush.Brush]: new ConfigFeature(FeatureType.CanvasAdaptiveZoomMobile, {
    enabled: true,
  }),
  [Brush.BrushDraw]: new ConfigFeature(FeatureType.CanvasAdaptiveZoomMobile, {
    enabled: true,
  }),
  [DrawType.Polygon]: new ConfigFeature(FeatureType.CanvasAdaptiveZoomMobile, {
    enabled: true,
  }),
  [SelectType.Box]: new ConfigFeature(FeatureType.CanvasAdaptiveZoomMobile, {
    enabled: false,
  }),
  [SelectType.Single]: new ConfigFeature(FeatureType.CanvasAdaptiveZoomMobile, {
    enabled: true,
  }),
  [SelectType.Polygon]: new ConfigFeature(FeatureType.CanvasAdaptiveZoomMobile, {
    enabled: true,
  }),
  [ThreadCommentType.Comment]: new ConfigFeature(FeatureType.CanvasAdaptiveZoomMobile, {
    enabled: featCanvasThreads.isEnabled,
  }),
};

/* -------------------------------- Sections; ------------------------------- */

export const featSectionProjectAiActions = new ConfigFeature(
  FeatureType.SectionProjectAiActions,
  {
    enabled: false,
    accessRoles: [UserRoleType.Admin],
    dependencies: [FeatureType.CanvasThreads],
    minVersion: '1.0.0',
  },
  {
    production: {
      enabled: false,
      accessRoles: [UserRoleType.User],
    },
    staging: {
      enabled: false,
    },
  }
);

/* --------------------------------- Projects ------------------------------ */

export const featProjectDuplicateAction = new ConfigFeature(FeatureType.ProjectDuplicateAction, {
  enabled: true,
  accessRoles: [UserRoleType.Admin],
});

/* --------------------------------- App ------------------------------ */

export const featDarkMode = new ConfigFeature(FeatureType.AppDarkMode, {
  enabled: true,
  accessRoles: [UserRoleType.Admin],
});
