// ----------------------------------------------------------------------

export enum ResourceType {
  Image = 'image',
  Images = 'images',
  Folder = 'folder',
  Folders = 'folders',
  AiModel = 'ai_model',
  AiModels = 'ai_models',
  Project = 'ai_project',
  Projects = 'ai_projects',
  Annotation = 'annotation',
  Annotations = 'annotations',
  Snapshot = 'ai_project_snapshot',
  Snapshots = 'ai_project_snapshots',
  Class = 'ai_project_snapshot_class',
  Classes = 'ai_project_snapshot_classes',
  Sketch = 'ai_project_snapshot_image',
  Sketches = 'ai_project_snapshot_images',
  Thread = 'thread',
  Threads = 'threads',
  Comment = 'comment',
  Comments = 'comments',
}
