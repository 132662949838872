import { paths } from 'src/routes/paths';

import * as pkg from '../../package.json';
import { NodeEnvType } from './types/types-env';

/* ----------------------------------- APP ---------------------------------- */

export const APP_NAME = pkg.name;
export const APP_VERSION = pkg.version;
export const APP_MODE = import.meta.env.MODE;
export const APP_ENV = (process.env.NODE_ENV ?? NodeEnvType.Development) as NodeEnvType;

/* ----------------------------------- API ---------------------------------- */

export const API_URL = import.meta.env.VITE_API_URL;
export const API_KEY = import.meta.env.VITE_API_KEY;
export const API_VERSION = import.meta.env.VITE_API_VERSION;

/* ----------------------------------- MISC --------------------------------- */

export const WEBSITE_NAME = 'SpatialX Orca';

export const PATH_AFTER_LOGIN = paths.dashboard.project.root.to();
