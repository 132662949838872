import * as amplitude from '@amplitude/analytics-browser';

import { LocalStorage } from 'src/helpers/local-storage';
import { AMPLITUDE_API_KEY } from 'src/configs/config-services';
import { StorageKeysType } from 'src/configs/types/types-storage';

import {
  AuthRegisterEvent,
  CreateAiModelEvent,
  AmplitudeEventsType,
  CanvasToolChosenEvent,
  UserInteractionViaType,
} from './types';

// ----------------------------------------------------------------------

export default class AmplitudeService {
  private static instance: AmplitudeService;

  private constructor() {
    amplitude.init(AMPLITUDE_API_KEY);
  }

  public static getInstance(): AmplitudeService {
    return this.instance;
  }

  // ----------------------------------------------------------------------

  public static init(): AmplitudeService {
    if (!this.instance) {
      this.instance = new AmplitudeService();
    }

    return this.getInstance();
  }

  public static logEvent(eventName: string, eventProperties?: { [key: string]: any }): void {
    const user = LocalStorage.getItem(StorageKeysType.User);
    amplitude.logEvent(eventName, { ...eventProperties, user });
  }

  public static logAuthRegisterEvent(
    firstname: string,
    lastname: string,
    organization: string,
    email: string,
    useCasePurpose: string
  ): void {
    const event: AuthRegisterEvent = {
      'first-name': firstname,
      'last-name': lastname,
      'org-name': organization,
      'email-address': email,
      'use-case-purpose': useCasePurpose,
    };
    this.logEvent(AmplitudeEventsType.AuthRegister, event);
  }

  public static logCreateAiModelEvent(projectId: string, snapshotId: string): void {
    const event: CreateAiModelEvent = {
      'project-id': projectId,
      'snapshot-id': snapshotId,
    };
    this.logEvent(AmplitudeEventsType.CreateAiModel, event);
  }

  public static logCanvasToolChosenEvent(tool: string, chosenVia: UserInteractionViaType): void {
    const event: CanvasToolChosenEvent = {
      'tool-name': tool,
      'chosen-via': chosenVia,
    };
    this.logEvent(AmplitudeEventsType.CanvasToolChosen, event);
  }
}
