import { JWT as JWTType } from 'aws-amplify/auth';

import { PageType } from 'src/helpers/page-mapper/types';
import { DynamicComponent } from 'src/services/api/types/types-dynamic-components';

import { IUser } from 'src/types/types-user';

// ----------------------------------------------------------------------

export type ActionMapType<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key;
      }
    : {
        type: Key;
        payload: M[Key];
      };
};

export type AuthUserType =
  | null
  | (IUser & {
      displayName: string;
      idToken: JWTType | undefined;
      accessToken: JWTType | undefined;
    });

export type AuthStateType = {
  loading: boolean;
  user: AuthUserType;
  allowedPages: DynamicComponent<PageType>[];
  navigationPages: DynamicComponent<PageType>[];
};

// ----------------------------------------------------------------------

export enum AuthMethodType {
  JWT = 'jwt',
  Amplify = 'amplify',
}

export type AmplifyContextType = {
  user: AuthStateType['user'];
  allowedPages: AuthStateType['allowedPages'];
  navigationPages: AuthStateType['navigationPages'];
  method: AuthMethodType;
  loading: boolean;
  authenticated: boolean;
  unauthenticated: boolean;
  login: (email: string, password: string) => Promise<unknown>;
  register: (
    firstname: string,
    lastname: string,
    organization: string,
    email: string,
    useCasePurpose: string
  ) => Promise<unknown>;
  logout: () => Promise<unknown>;
};

// ----------------------------------------------------------------------

export type Me = {
  user: IUser;
  allowed_pages: DynamicComponent<PageType>[];
  navigation_pages: DynamicComponent<PageType>[];
};
