import { ResourceType } from 'src/services/api/types/types-resources';

// ----------------------------------------------------------------------

export enum Params {
  ImageId = `${ResourceType.Image}_id`,
  FolderId = `${ResourceType.Folder}_id`,
  AiModelId = `${ResourceType.AiModel}_id`,
  ProjectId = `${ResourceType.Project}_id`,
  SnapshotId = `${ResourceType.Snapshot}_id`,
  SketchId = `${ResourceType.Sketch}_id`,
  ClassId = `${ResourceType.Class}_id`,
  ThreadId = `${ResourceType.Thread}_id`,
  CommentId = `${ResourceType.Comment}_id`,
  AnnotationId = `${ResourceType.Annotation}_id`,
}

export enum RoutePlaceholder {
  ImageId = `<int:${Params.ImageId}>`,
  FolderId = `<int:${Params.FolderId}>`,
  AiModelId = `<int:${Params.AiModelId}>`,
  ProjectId = `<int:${Params.ProjectId}>`,
  SnapshotId = `<int:${Params.SnapshotId}>`,
  SketchId = `<int:${Params.SketchId}>`,
  ClassId = `<int:${Params.ClassId}>`,
  ThreadId = `<int:${Params.ThreadId}>`,
  CommentId = `<int:${Params.CommentId}>`,
  AnnotationId = `<int:${Params.AnnotationId}>`,
}

// ----------------------------------------------------------------------

export type RoutePlaceholderMapperType = {
  [key in Params]: RoutePlaceholder;
};
