/* eslint-disable perfectionist/sort-imports */
import 'src/global.css';

// i18n
import 'src/locales/i18n';

// ----------------------------------------------------------------------

import React, { useEffect } from 'react';
import { useScrollToTop } from 'src/hooks/use-scroll-to-top';
import { useAppInit } from 'src/hooks/custom/use-app-init';

import { LocalizationProvider } from 'src/locales';
import { AuthProvider } from 'src/services/auth/context';
import ProgressBar from 'src/components/progress-bar';
import { MotionLazy } from 'src/components/animate/motion-lazy';
import SnackbarProvider from 'src/components/snackbar/snackbar-provider';
import { SettingsDrawer, defaultSettings, SettingsProvider } from 'src/components/settings';

import { LoadingScreen } from 'src/components/loading-screen';
import { useAppSelector } from 'src/store/hooks';
import { withProfiler, withErrorBoundary } from '@sentry/react';
import ThemeProvider from 'src/theme';
import RouterNavigateSetter from './routes/components/router-navigate-setter';
import useUnsavedChangesWarning from './hooks/use-unsaved-changes-warning';
import { configSentry } from './configs/config-services';
import ViewErrorFallback from './sections/error/vew-error-fallback';
import { store } from './store';
import { APP_ENV } from './configs/config-global';

// ----------------------------------------------------------------------

interface Props {
  children?: React.ReactNode;
}

function App({ children }: Props) {
  useScrollToTop();

  const { appLoading } = useAppSelector(({ app }) => app);
  const { writeApiCallsPending } = useAppSelector(({ app }) => app);
  const { isLoading: initializing, error: initializationError } = useAppInit();
  const { setEnabled: setUnsavedChangesWarning } = useUnsavedChangesWarning(
    Boolean(writeApiCallsPending)
  );

  useEffect(() => {
    const showWarningAlert = writeApiCallsPending !== 0;
    setUnsavedChangesWarning(showWarningAlert);
  }, [writeApiCallsPending, setUnsavedChangesWarning]);

  if (appLoading || initializing) {
    return <LoadingScreen sx={{ paddingTop: '50vh', width: 'auto' }} />;
  }

  if (initializationError) {
    throw new Error(`App Initialization Error: ${initializationError}`);
  }

  return (
    <AuthProvider>
      <LocalizationProvider>
        <SettingsProvider defaultSettings={defaultSettings}>
          <ThemeProvider>
            <MotionLazy>
              <SnackbarProvider>
                <RouterNavigateSetter />
                <SettingsDrawer />
                <ProgressBar />
                {children}
              </SnackbarProvider>
            </MotionLazy>
          </ThemeProvider>
        </SettingsProvider>
      </LocalizationProvider>
    </AuthProvider>
  );
}

export default withProfiler(
  withErrorBoundary(App, {
    beforeCapture: (scope) => {
      scope.setFingerprint(['app-error-boundary']);
      scope.setTag('error.origin', 'error_boundary');
      // Add extra context
      scope.setContext('app_state', {
        writeApiCallsPending: store.getState().app.writeApiCallsPending,
        appLoading: store.getState().app.appLoading,
      });
    },
    fallback: ({ error, componentStack, resetError }) => (
      <ViewErrorFallback error={error} componentStack={componentStack} resetError={resetError} />
    ),
    onError: (error, componentStack, eventId) => {
      // TODO: replace with logger (app logger interface)
      if (APP_ENV === 'development') {
        console.group('🔥 App Error Boundary');
        console.error('Error:', error);
        console.error('Component Stack:', componentStack);
        console.error('Event ID:', eventId);
        console.groupEnd();
      }
    },
  }),
  {
    name: 'App',
    disabled: !configSentry.withProfiler,
  }
);
